import React from 'react';
import ReactDOM from 'react-dom';

import { Router, Switch, Route, Redirect } from 'react-router-dom';

import history from './history';

import Home from './pages/Home';
import Contact from './pages/Contact';
// import DevelopmentPage from './pages/DevelopmentPage';

const Routes = () => (
  <Switch>
    <Route exact path="/" component={Home} />
    {/* <Redirect from="/desarrollo-software-a-medida" to="/servicios/desarrollo-de-software"/> */}
    {/* <Route exact path="/servicios/desarrollo-de-software" component={DevelopmentPage} /> */}
x    <Route exact path="/contacto-web" component={Contact} />
    <Redirect from="/*" to="/"/>
  </Switch>
);

ReactDOM.hydrate(
  <React.StrictMode>
    <Router history={history}>
     <div>
      <Routes/>
     </div>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

export {
  Routes
};

// serviceWorker.register();
