import {useState} from 'react';
import Reaptcha from 'reaptcha';

import TelegramService from '../services/TelegramService';

const Trust = ({
  goTo
}) => {

  return (
    <div className="container-fluid px-0 gray-background hght250 pdeasg100">
      <div className="row no-gutters pdng5perct">
        <div className="col-md-6" style={{paddingTop:"80px", paddingBottom:"80px"}}>
          <h2 className="dark-text">Confia en nosotros <img
              loading="lazy"
              width="32"
              height="32"
              className="img-owl-check"
              alt={"check"}
              src={"/static/img/check-mark.png"}/></h2>
          <p className="p-text-dark">
            En nuestro equipo contamos con profesionales de vasta trayectoria y experiencia laboral, los que se han especializado en las materias propias de las áreas señaladas, 
            junto con la utilización de modelos y herramientas existentes, acordes a las necesidades de cada cliente, con el fin de apoyar a que estos puedan maximizar su rentabilidad, 
            crecimiento y competitividad en el mercado en que participan.
          </p>
          <button
                    type="submit"
                    className="btn btn-custom-submit-contact"
                    onClick={()=>goTo('/contacto-web')}>{'Más Información'}</button>
        </div>
        <div className="col-md-6 ta-768min">
          <img
              loading="lazy"
              className="img-owl-1"
              width="300"
              height="450"
              alt={"confia-dimasoft"}
              src={"/static/img/confia-en-nosotros.png"}/>
        </div>
      </div>
    </div>
  )
}

export default Trust;
