const ConsultingService = () => {
  return (
    <div className="container-fluid px-0 dark-background-rocket hght250">
      <div className="row no-gutters pdng5perct">
        <div className="col-md-8">
          <h2 className="white-text">CONSULTORIA TRIBUTARIA</h2>
          <p className="p-text-white">Nuestro servicio contempla la externalización de la información Contable, Tributaria y Laboral de las empresas, como también la asesoría permanente o esporádica en ellas.</p>
        </div>
        <div className="col-md-4"></div>
      </div>
    </div>
  )
}

export default ConsultingService;
