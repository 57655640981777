import { useRef } from 'react';

import Header from '../components/Header.js';
import Footer from '../components/Footer.js';
import Banner from '../components/Banner.js';

import Services from '../segments/Services.js';
import OurClients from '../segments/OurClients.js';
import Contact from '../segments/Contact.js';

import TestingService from '../services/TestingService.js';


const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);

const Home = () => {

  const contactRef = useRef(null);

  const openWhatsapp = () => {
    let url = "https://api.whatsapp.com/send/?phone=56991981647&text&app_absent=0";
    window.open(url);
  }

  const openCall = () => {
    let url = "tel:0056997281587";
    window.open(url);
  }

  const testService = () => {
    TestingService.functionCustom().then((response) => {
      console.log(response);
    })
    .catch((err) => {
      console.log(err);
    });
  };

  return (
    <div className="App">
      <Header
        contactRef={contactRef}
        scrollToRef={scrollToRef}
        openWhatsapp={openWhatsapp}
        openCall={openCall}
      />
      <Contact
        contactRef={contactRef}
      />
      <Footer
        openWhatsapp={openWhatsapp}
      />
    </div>
  );
}

export default Home;
