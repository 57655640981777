import { FaWhatsapp } from 'react-icons/fa';

const Footer = ({
  openWhatsapp
}) => {

  return (
    <footer>
        <div className="container mt-4 footer_segment">
            <div className="row accordion" id="footer_cols">
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 footer_1rst mb-30">
                    <p className="d-xl-block d-lg-block d-md-block">
                      <string style={{ fontSize:"18px"}}>CONTÁCTENOS</string>
                    </p>
                    <div>
                        <ul className="list-unstyled">
                          <li className="text-footer">
                            <a href="mailto:info@grupodct.cl" width="151" height="48">info@grupodct.cl</a>
                          </li>
                          <li className="text-footer">
                            <a href="#" width="110" height="48">Pocuro #2378 – Providencia - Santiago</a>
                          </li>
                          <li className="text-footer">
                            <a href="tel:0056991981647">+56 9 3239 2429</a>
                          </li>
                        </ul>
                    </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 footer_2rst mb-30">
                    <hr className="hr-grey d-md-none d-block"/>
                    <div className='row'>
                      <a className='col-12' href='#' target='_blank'>
                        <img
                          loading="lazy"
                          width="400"
                          height="auto"
                          src="/static/img/dimasoft-logo-group.png"
                          alt="logo-grupo-dimasoft"
                          title="Grupo Dimasoft"
                          className="logo-footer-dima img-fluid"
                        />
                      </a>
                      {/* <a href="/">
                        <img
                          loading="lazy"
                          width="180"
                          height="50"
                          src="/static/img/dimasoft-logo.jpg"
                          alt="empresa-desarrollo-software-chile"
                          title="SolutionOps SpA"
                          className="logo-footer-dima img-fluid"
                          />
                      </a> */}
                      {/* <a href="/">
                        <img
                          loading="lazy"
                          width="400"
                          height="auto"
                          src="/static/img/dimasoft-logo-large.png"
                          alt="logo-grupo-dimasoft"
                          title="Grupo Dimasoft"
                          className="logo-footer-dima img-fluid"
                          />
                      </a> */}
                    </div>
                    <div className='row'>
                      <a className='col-4' href='https://dimasoft.cl/' target='_blank'>
                        <img
                            loading="lazy"
                            width="130"
                            height="auto"
                            src="/static/img/dimasoft-logo-original.png"
                            alt="logo-grupo-dimasoft"
                            title="Grupo Dimasoft"
                            className="logo-footer-dima img-fluid"
                        />
                      </a>
                      <a className='col-4 mt-4' href='https://solutionops.cl/' target='_blank'>
                        <img
                            loading="lazy"
                            width="400"
                            height="auto"
                            src="/static/img/solutionops-logo.png"
                            alt="logo-grupo-dimasoft"
                            title="Grupo Dimasoft"
                            className="logo-footer-dima img-fluid"
                        />
                      </a>
                      <a className='col-4 mt-3' href='#' target='_blank'>
                        <img
                            loading="lazy"
                            width="400"
                            height="auto"
                            src="/static/img/dimasoft-consultora-logo.png"
                            alt="logo-grupo-dimasoft"
                            title="Grupo Dimasoft"
                            className="logo-footer-dima img-fluid"
                        />
                      </a>
                    </div>
                </div>
            </div>
        </div>
        <div id="copyright" className="container-fluid px-0">
            <div className="separator-container py-3">
                <hr className="blue border-top-8px m-0"/>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <p className="text-left-footer">© 2024 Dimasoft</p>
                    </div>
                </div>
            </div>
        </div>

        <a className="card-link btn-whatsapp-1 fixed-btn" name="btn-whatsapp-1" aria-label="Contacta a nuestro equipo" onClick={() => openWhatsapp()} href="#"><FaWhatsapp /></a>


    </footer>
  )
}

export default Footer;
