import Carousel from 'react-material-ui-carousel'
import Item from "./Item";
import { useState } from "react";

const Banner = ({ scrollToRef, serviceRef, contactRef, testService }) => {
  
  const [contact, setContact] = useState({
    name: "",
    phone: "",
    email: "",
    message: "",
  });

  var items = [
    {
        title: "Bienvenido",
        description: 'Consultorías Dimasoft Spa., empresa del Grupo Dimasoft, cuenta con profesionales de alto nivel, que tienen como objetivo principal poder entregar a nuestros clientes una solución integral en la gestión de las áreas de Contabilidad, Remuneraciones, Tributaria y Auditoria. Contamos con vasta experiencia en rubros tales como Comercio, Construcción, Transportes, Servicios, Agrícola, entre otros.',
        image: "/static/img/dimasoft-chile-banner-min-1.webp"
    },
    {
        title: "Misión",
        description: "Nuestra misión es prestar servicios integrales de Asesoría Técnica y Consultoría en las áreas Contable, Laboral, Tributaria y Auditoria a empresas.",
        image: "/static/img/dimasoft-chile-banner-min-2.webp"

    },
    {
        title: "Objetivo",
        description: "Nuestro principal objetivo es dar solución integral a la problemática de las empresas en estas áreas, dando respuestas en base Técnica a las necesidades de nuestros clientes en forma eficaz, eficiente y oportuna.",
        image: "/static/img/dimasoft-chile-banner-min-3.webp"

    },
    {
        title: "Segmento de Clientes",
        description: "Nos enfocamos principalmente en Empresas Medianas, Dueños o Socios de Empresas y Otros Inversionistas.",
        image: "/static/img/dimasoft-chile-banner-min-4.webp"

    }
  ]

  return (
    <div className="container-fluid px-0 ">
      <Carousel className="row no-gutters" style={{margin:"0", padding:"0"}}>
          {
            items.map( (item, i) => <Item style={{width:"100vw"}} key={i} item={item} contact={contact} setContact={setContact} /> )
          }
      </Carousel>
    </div>
  );
};

export default Banner;
