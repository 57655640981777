const Services = ({
  scrollToRef,
  serviceRef,
  goTo,
}) => {

  const services = [
    {
      name: "ASESORIA CONTABLE",
      content: ["Administración General de Contabilidad. Preparación de Balance y Estado de Resultados. Contabilidades Atrasadas. Apertura e Inicio Actividades ante SII."],
      image: "/static/img/services/asesorias-contables-dimasoft.svg"
    },
    {
      name: "ASESORIA TRIBUTARIA",
      content: "Determinación de Impuesto Mensual de IVA y otros. Determinación de Base Imponible y Renta Liquida. Determinación de Registros de Rentas Empresariales. Declaraciones Anuales de Impuesto Renta e IGC. Regímenes Tributarios según Reforma Tributaria. Diagnóstico Tributario y Optimización Carga Impositiva. Cumplimiento de las obligaciones Tributarias. Declaraciones Juradas Anuales al SII. Fiscalizaciones del Servicio de Impuestos Internos.",
      image: "/static/img/services/asesorias-tributarias-dimasoft.svg"
    },
    {
      name: "RRHH Y REMUNERACIONES",
      content: "Administración General de Recursos Humanos. Confección Liquidación de Sueldo. Confección Contratos de Trabajo y Finiquitos. Confección Reglamento Interno ( RIOHS ).",
      image: "/static/img/services/asesorias-laborales-dimasoft.svg"
    },
    {
      name: "AUDITORIAS Y CONSULTORIAS",
      content: "Auditorias a Estados Financieros. Auditorias Tributarias y Asesoramiento. Auditorias Laborales y Asesoramiento. Evaluación Control Interno Contable. Revisión Procesos Administrativo-Contable.",
      image: "/static/img/services/auditorias-dimasoft.svg"
    }
  ];

  return (
    <div className="container-service segment-spacing center-text-segment services-segment" ref={serviceRef}>
      <div className="row">
        <div className="col-md-12">
          <h2 className="dark-header-text mb50">NUESTROS SERVICIOS</h2>
        </div>

        {
          services.map((service, index)=>(
            <div
              key={index}
              className="col-lg-3 service-box">

              <div className="align-icon">
                <div className="aio-icon-img  uavc-circle">
                  <img
                    loading="lazy"
                    width="80"
                    height="80"
                    className="img-icon"
                    alt={service.name}
                    src={service.image}/>
                </div>
                <h3 className="dark-header-text service-title">{service.name}</h3>
                <p className="p-text-service-box text-justify" style={{fontSize:"6px"}}>{service.content}</p>
                <div className="col-md-12 mt-5">
                  <button
                    type="submit"
                    className="btn btn-custom-submit-contact"
                    onClick={()=>goTo('/contacto-web')}>{'Más Información'}</button>
                </div>
              </div>

            </div>
          ))
        }
      </div>
    </div>
  )
}

export default Services;
