import { useState } from "react";
import SweetAlert2 from 'react-sweetalert2';
import Reaptcha from "reaptcha";

import TelegramService from "../services/TelegramService";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

function Form({ contact, setContact }) {

  const [alert, setAlert] = useState({
    title: "Error",
    message: "Por favor intente nuevamente más tarde",
  });

  const [loader, setLoader] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [verified, setVerified] = useState(false);

  const submitForm = (e) => {
    e.preventDefault();
    
    if(contact.name==="" || contact.phone==="56" || contact.phone==="+56" || contact.email === ""){

      setAlert({
        title: 'Alerta',
        message: 'Debe especificar su nombre, teléfono y correo electrónico',
        icon: 'warning',
        color: '#ff8000'
      });
      setShowAlert(true);
      return;
    }

    if(contact.phone.length<11){
      setAlert({
        title: 'Alerta',
        message: 'Debe agregar un número de teléfono válido (+56 9 9999 9999)',
        icon: 'warning',
        color: '#ff8000'
      });
      setShowAlert(true);
      return;
    }

    if((contact.phone.substring(0, 3) !== "569") && (contact.phone.substring(0, 3) !== "562")){
      setAlert({
        title: 'Alerta',
        message: 'Debe agregar un número de teléfono válido (+56 9 9999 9999)',
        icon: 'warning',
        color: '#ff8000'
      });
      setShowAlert(true);
      return;
    }

    if(!verified){
      setAlert({
        title: 'Alerta',
        message: 'Debe validar el captcha',
        icon: 'error',
        color: '#ff8000'
      });
      setShowAlert(true);

      return;
    }

    window.gtag_report_conversion();

    setLoader(true);

    let msg = "";
    msg = `${msg}Nombre: ${contact.name}\n`;
    msg = `${msg}Teléfono: ${contact.phone}\n`;
    msg = `${msg}Email: ${contact.email}\n`;
    msg = `${msg}Mensaje: ${contact.message}\n`;

    let body = {
        "text": msg,
        "chat_id": `${process.env.REACT_APP_TELEGRAM_CHAT_ID}`
    };

    TelegramService.sendMessage(body).then((response) => {
      console.log(response);

      if(response.status===200){
        setAlert({
          title: 'Mensaje Enviado',
          message: 'Su mensaje ha sido enviado satisfactoriamente',
          icon: 'success',
          color: '#03a9f4'
        });

        setContact({
          name: '',
          company: '',
          rut: '',
          phone: '',
          email: '',
          message: ''
        });
      }

      setShowAlert(true);
      setLoader(false);
    })
    .catch((err) => {
      console.log(err);
      setShowAlert(true);
      setLoader(false);
    });
  }

  const handleChange = (e) => {
    setContact({
      ...contact,
      [e.target.name]: e.target.value,
    });
  };

  const onVerify = (recaptchaResponse) => {
    setVerified(true);
  };

  const { name, company, rut, phone, email, message } = contact;

  return (
    <div
    className="px-4 form-home-banner"
    >
      <form onSubmit={submitForm}>
        <p className="text-center mt-2" style={{ color: "#737373" }}>
          CONTÁCTENOS AQUÍ.
        </p>

        <div className="form-group ">
          
          <input
            type="text"
            className="form-control mt-4"
            placeholder="Nombre y Apellido"
            autoComplete="off"
            name="name"
            onChange={handleChange}
            value={name}
          />
        </div>

        <div className="form-group">
          {/* <input
            type="number"
            className="form-control mt-4"
            name="phone"
            onChange={handleChange}
            value={phone}
            placeholder=" Telefono"
            autoComplete="off"
            required
          /> */}
          <PhoneInput
            country={'cl'}
            masks={{cl: '. .... ....'}}
            name="phone"
            onlyCountries={['cl']}
            className="mt-4 w100-easg"
            value={phone}
            placeholder={'9 9999 9999'}
            onChange={phone => setContact({
              ...contact,
              phone: phone
            })}
          />
        </div>

        <div className="form-group">
          <input
            type="email"
            className="form-control mt-4"
            onChange={handleChange}
            value={email}
            name="email"
            placeholder="Correo Electrónico"
          />
        </div>

        <div className="form-group">
          <textarea
            className="form-control"
            name="message"
            onChange={handleChange}
            rows="3"
            value={message}
            placeholder="Escribe tu mensaje aqui."
          ></textarea>

          <Reaptcha
            sitekey="6LfROeYpAAAAAOMRn79W2aGDjDRivi9MSK3bOc8g"
            onVerify={onVerify}
          />
        </div>
        <button
          name="submit-btn-1"
          type="submit"
          className="btn btn-form-1"
          disabled={loader}
        >
          {!loader ? "Enviar" : "Cargando..."}
        </button>
        <SweetAlert2
              show={showAlert}
              title={alert.title}
              text={alert.message}
              icon={alert.icon}
              confirmButtonColor={alert.color}
              onConfirm={() => setShowAlert(false)}
            /> 
        {/* <button type="submit" className="btn btn-primary">
          Enviar
        </button> */}
      </form>
    </div>
  );
}

export default Form;
